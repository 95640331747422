import React, { useEffect, useState } from 'react';
import {
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import PriceFormat from './PriceFormat';
import InstantRebates from './InstantRebates';
import SavingsCenterPromos from './SavingsCenterPromos';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-simple.style.scss';
import { LAYOUT_TYPES, triggerNewRelicEvent } from '../../utils/utils';
import { SignInLink } from './subcomponents/signInLink/SignInLink';

export const PriceSimple = ({
  basic,
  channel,
  disableRangePricing,
  displayEachUom,
  hideBadge,
  hideBulkPrice,
  hideLimitPerOrder,
  hidePromotions,
  hideRebates,
  hideSavingsText,
  omitDetails,
  onSavingsCenterToggle,
  priceBadge,
  skuModel,
  zipCode,
  disableOuterSpacing,
  isClearance,
  useNewMapPolicy
}) => {
  const { pricing = {} } = skuModel || {};
  const { isCustomerIdentified = false } = useThdCustomer() || {};

  useEffect(() => {
    if (pricing?.itemId) {
      triggerNewRelicEvent({
        actionName: isCustomerIdentified ? 'PriceDisplayedAfterAuth' : 'MapPriceDisplayed',
        itemId: pricing?.itemId,
        layoutType: 'SimpleView',
        subExperience: 'PriceSimple',
        customerStatus: isCustomerIdentified ? 'authenticated' : 'unauthenticated',
        pricing,
        isCustomerIdentified // Pass the authentication status
      });
    }
  }, [isCustomerIdentified, pricing?.itemId, useNewMapPolicy]);

  const uomWrapper = classNames('sui-ml-2 sui-font-bold sui-text-xs sui-leading-none');
  const outerPriceWrapper = classNames('sui-flex sui-flex-col');
  const responsivePriceWrapper = classNames('sui-flex sui-flex-row sui-leading-none');
  const innerPriceWrapper = classNames('sui-flex sui-flex-row sui-leading-none');
  const strikethroughWrapper = classNames('sui-text-xs sui-leading-none sui-flex sui-flex-col sui-ml-2');

  // BRIO Skus
  const handleBRIOSku = () => {
    const isCustomKitchenCabinetsSku = pricing?.category === 'Custom Kitchen Cabinets';
    const quoteMessage = isCustomKitchenCabinetsSku ? '' : 'Get a quote during a FREE design Consultation';
    const howToBuyDetails = `Home Depot Installation ${isCustomKitchenCabinetsSku ? 'Available' : 'Required'}`;
    return (
      <div className="brio__wrapper">
        <div className="brio__quote-message">
          {quoteMessage}
        </div>
        <div className="brio__how-to-buy-details">
          {howToBuyDetails}
        </div>
      </div>
    );
  };

  // HIDE_PRICE/LIVE_GOODS
  const handleHidePrice = () => <div className="price__contact-message">Contact your local store for price</div>;

  // Limit per order
  const handleLimitPerOrder = ({
    pricingValues, priceBadge, uom, save, price, hasEcoRebates,
    bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    if (!!pricing.quantityLimit) {
      return (
        <div className={outerPriceWrapper}>
          {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'sui-text-xs sui-mb-2')}
          <div className={responsivePriceWrapper}>
            <div className={innerPriceWrapper}>
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
              />
              {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)}</span>}
            </div>
            <div className={strikethroughWrapper}>
              <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
              <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
            </div>
          </div>
          <div>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
          </div>
        </div>
      );
    }
    return '';
  }

  // Unit price regular, Unit price with strike through
  const handleUnitPrice = ({
    alternatePrice, alternateUom, uom, price, priceBadge,
    save, pricingValues, unitPricingValues, bulkPrice, bulkPriceThresholdQty, hasEcoRebates
  }) => {
    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    const { unitWasPrice, unitNowPrice } = pricing;
    const pricePerUnit = unitWasPrice || unitNowPrice;
    if (pricePerUnit) {
      const uomIsSqFt = uom === 'sq. ft.';
      const priceToDisplay = uomIsSqFt ? price : alternatePrice.toString();
      const wasPriceToDisplay = uomIsSqFt ? unitPricingValues : pricingValues;
      const showDollarOff = !uomIsSqFt;
      return (
        <div id="unit-case-price" className={outerPriceWrapper}>
          {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'sui-text-xs sui-mb-2')}

          <div className={responsivePriceWrapper}>
            <div className={innerPriceWrapper}>
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
              />
              {uomIsSqFt && (
                <div className="sui-flex sui-flex-col sui-ml-2 sui-text-xs">
                  <span className="sui-font-bold">{uom && helpers.parseUom(uom, displayEachUom)}</span>
                  <div className={innerPriceWrapper}>
                    <span>({helpers.handlePriceParsing(unitPricingValues ? alternatePrice : pricing.nowPrice, false)}</span>
                    <span>{`${pricing.uom && helpers.parseUom(pricing.uom, displayEachUom)})`}</span>
                  </div>
                </div>
              )}
              {!uomIsSqFt && (
                <div className="sui-ml-2 sui-flex sui-flex-col">
                  <span className="sui-font-bold sui-text-xs sui-leading-none">{alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
                  {pricing.unitsPerCase > 1 && (
                    <span className="sui-text-xs sm:sui-mt-1">({helpers.handlePriceParsing(price, false)} <span>{uom && helpers.parseUom(uom, displayEachUom)}</span>)</span>
                  )}
                </div>
              )}
            </div>
            {wasPriceToDisplay && (
              <div className={strikethroughWrapper}>
                <span className="sui-text-subtle">{helpers.getWasPrice(wasPriceToDisplay)}</span>
                <span className="sui-text-success">{helpers.getSavingsText(true, hideSavingsText, save, showDollarOff)}</span>
              </div>
            )}
            </div>
          <div>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
          </div>
        </div>
      );
    }

    return (
      <div id="unit-price">
        {pricing.unitOfMeasureCoverage && (
          <div className="price__unit-cover">
            {`${pricing.unitOfMeasureCoverage} (${coveragePriceTemplate} ${uom && helpers.parseUom(uom, displayEachUom)})`}
          </div>
        )}
        <PriceFormat
          price={alternatePrice.toString()}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        {!pricingValues && <span className="priceFormat__unit-alignment">{alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>}
        {pricingValues &&
          <div className="price__was-price">
            <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(false, hideSavingsText, save)}
          </div>
        }
        {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
        {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, false, LAYOUT_TYPES.SIMPLE)}
      </div>
    );
  };

  // Was price
  const handleWasPrice = ({
    pricingValues, save, priceBadge, uom
  }) => {

    return (
      <div id="was-price">
          <div className={responsivePriceWrapper}>
            <div className={innerPriceWrapper}>
              <PriceFormat
                price={pricingValues.nowPrice}
                priceBadge={priceBadge}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
              />
              {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)} </span>}
            </div>
            <div className={strikethroughWrapper}>
              <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
              <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
            </div>

          </div>

      </div>
    );
  };

  // Starting At price
  const handleStartingAt = ({
    pricingValues, save, price, priceBadge, uom
  }) => (
    <div id="start-at">
      <div className="sui-font-regular sui-text-base sui-leading-none sui-mb-2">Starting at</div>
      <div className={innerPriceWrapper}>
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
          {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)} </span>}
        <div className={strikethroughWrapper}>
          <span className='sui-text-subtle'>{helpers.getWasPrice(pricingValues)}</span>
          <span className='sui-text-success'>{helpers.getSavingsText(false, hideSavingsText, save)}</span>
        </div>
      </div>
    </div>
  );

  // Map price
  const handleMapPrice = ({
    mapPrice, pricingValues, uom, priceBadge, pricingMessage, hasEcoRebates, mapPolicy, save, hasStrikeThrough
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';

    hasStrikeThrough = useNewMapPolicy ? hasStrikeThrough : true;

    let priceToDisplay = '';
    const savingsExists = save?.dollarOff && save?.percentageOff;
    if (mapPrice) {
      priceToDisplay = mapPrice.wasPrice;
      hasStrikeThrough = mapPrice.hasStrikeThrough;
    } else if (pricingValues) {
      priceToDisplay = useNewMapPolicy && pricingValues.nowPrice ? pricingValues.nowPrice : pricingValues.wasPrice;
    }
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    const GetToolTip = () => {
      return <Button link contentClass="price__tooltip" >
        <Tooltip
          channel={channel}
          closeButton
          content={tooltipContent}
          maxWidth={300}
          placement="bottom"
        >
          <div id={tooltipId}>{pricingMessage}</div>
        </Tooltip>
      </Button>
    }
    let mapPolicyMessage;
    if (useNewMapPolicy && mapPolicy === 'showSpecialPriceIfAuth' && !isCustomerIdentified) {
      mapPolicyMessage = <SignInLink layoutType='simple' itemId={pricing?.itemId} />
    } else if (mapPolicy !== 'showSpecialPriceIfAuth') {
      mapPolicyMessage = <GetToolTip />
    }
    return (
      <div>
        <div className="price__map-price">
          {(mapPrice || pricingValues) && (
            <div className={responsivePriceWrapper}>
              <span className="price__message">
                <PriceFormat
                  price={priceToDisplay}
                  priceBadge={priceBadge}
                  hideBadge={hideBadge}
                  disableOuterSpacing={disableOuterSpacing}
                  hasStrikeThrough={hasStrikeThrough}
                />
              </span>
              {uom && <span className="price__uom">{helpers.parseUom(uom, displayEachUom)}</span>}
              {(useNewMapPolicy && savingsExists) && (
                <div
                  className={strikethroughWrapper}>
                  <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
                  <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
                </div>
              )}
            </div>
          )}
          {mapPolicyMessage}
        </div>
        {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.SIMPLE)}
      </div>
    );
  };

  // Bulk price
  const handleBulkPrice = ({
    price, priceBadge, uom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="bulk-price" className={outerPriceWrapper}>
        <div className={innerPriceWrapper}>
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          {uom && (
            <span className={uomWrapper}>{uom && helpers.parseUom(uom, displayEachUom)}</span>
          )}

          <div className={strikethroughWrapper}>
            <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
            <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
          </div>
        </div>
        <span>{helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}</span>
      </div>
    );
  };

  // Instant Rebate
  const handleInstantRebate = ({
    price, priceBadge, uom, pricingValues
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="instant-rebate" className={outerPriceWrapper}>
        <div className={innerPriceWrapper}>
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="sui-ml-2">
            {uom && <span>{helpers.parseUom(uom, displayEachUom)}</span>}
            <span className="sui-color-subtle sui-font-regular sui-text-xs sui-leading-none">{helpers.getWasPrice(pricingValues)}</span>
          </div>
        </div>
        {!hideRebates && (
          <InstantRebates
            storePromotion={pricing?.storePromotion}
            price={pricing}
            channel={channel}
          />
        )}
      </div>
    );
  };

  // Range Price
  const handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, save
  }) => (
    <div id="range-price">
      <div className="sui-flex sui-flex-row sui-leading-none sui-gap-1">
        <PriceFormat
          price={minPrice}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <span className="u__bold price__range"> - </span>
        <PriceFormat
          price={maxPrice}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
      </div>
      <div>
        {helpers.getWasPriceRange(wasMinPrice, wasMaxPrice)}
        {helpers.getSavingsText(true, hideSavingsText, save, false)}
      </div>
    </div>
  );

  // Eco Rebate
  const handleEcoRebates = ({
    price, priceBadge, uom, pricingValues, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty, mapPolicy
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="eco-rebate-price">
        {(bulkPrice && !hideBulkPrice) && handleBulkPrice({
          price, priceBadge, uom, displayEachUom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && handleMapPrice({
          mapPrice, price, priceBadge, uom, displayEachUom, pricingMessage, mapPolicy, save
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <div className={responsivePriceWrapper}>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
            />
            <div className={strikethroughWrapper}>
              {uom && (
                <span>{uom && helpers.parseUom(uom, displayEachUom)}</span>
              )}
              <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
              <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
            </div>
          </div>
        )}
        <span>{helpers.getRebates(hideRebates, true, pricing.itemId, zipCode, false)}</span>
      </div>
    );
  }

  // No details
  const handleNoDetailsPrice = ({
    price, uom, pricingValues, save, priceBadge
  }) => (
    <div id="no-details-price">
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      <div className="price__was-price">
        {uom && <span>{helpers.parseUom(uom, displayEachUom)}</span>}
        {helpers.getWasPrice(pricingValues)}
        {helpers.getSavingsText(false, hideSavingsText, save)}
      </div>
    </div>
  );

  // Standard price
  const handleStandardPrice = ({
    price, priceBadge, uom
  }) => (
    <div id="standard-price" className={innerPriceWrapper}>
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)}</span>}
    </div>
  );

  // Basic price
  const handleBasicPrice = ({ price }) => (
    <PriceFormat
      basic
      price={price}
      disableOuterSpacing={disableOuterSpacing}
    />
  );

  const renderByType = ({ _price, type }) => {
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return handleStandardPrice(_price);
      case PRICE_TYPES.BASIC:
        return handleBasicPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return handleWasPrice(_price);
      case PRICE_TYPES.STARTING_AT:
        return handleStartingAt(_price);
      case PRICE_TYPES.MAP_PRICE:
        return handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return handleUnitPrice(_price, pricing);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return handleLimitPerOrder(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return handleRangePrice(_price);
      case PRICE_TYPES.BRIO:
        return handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return handleNoDetailsPrice(_price);
      default: return null;
    }
  }

  const savingCenterPromoMessage = helpers.getSavingCenterPromoMessage(pricing.savingsCenterPromos);
  const wrapperClass = classNames({
    'sui-flex sui-flex-col': true
  });

  return (
    <div className={wrapperClass} data-testid="price-simple">
      {renderByType(helpers.getPricingObject({
        skuModel, displayEachUom, omitDetails, basic, disableRangePricing, useNewMapPolicy
      }))}
      {(!skuModel.preferredPriceFlag && !!savingCenterPromoMessage && !hidePromotions) && (
        <SavingsCenterPromos
          itemId={pricing.itemId}
          channel={channel}
          onSavingsCenterToggle={onSavingsCenterToggle}
          savingCenterPromoMessage={savingCenterPromoMessage}
        />
      )}
    </div>
  );
}

PriceSimple.propTypes = {
  /**
   * To show the basic price format
   */
  basic: bool,
  /**
   * device type
   */
  channel: string.isRequired,
  /**
   * disables the display of range pricing often seen in generic sku product pods
   */
  disableRangePricing: bool,
  /**
   * disables the "each" uom treatment
   */
  displayEachUom: bool,
  /**
   * suppresses the display of badges, such as bulk pricing
   */
  hideBadge: bool,
  /**
   * disables bulk pricing messaging
   */
  hideBulkPrice: bool,
  /**
   * suppresses the display of limit per order text
   */
  hideLimitPerOrder: bool,
  /**
   * disables display of the short description for promotions
   */
  hidePromotions: bool,
  /**
   * Hide Savings Text
   */
  hideSavingsText: bool,
  /**
   * Hide Rebate Messaging and prevent Rebate Calls
   */
  hideRebates: bool,
  /**
   * to display only a very simplified view of the price
   */
  omitDetails: bool,
  /**
   * callback triggered when savings center tooltip link is clicked
   */
  onSavingsCenterToggle: func,
  /**
   * the transformed price data
   */
  skuModel: shape({
    pricing: shape({
      itemId: oneOfType([number, string]),
      uom: string,
      unitsPerCase: number,
      wasPrice: oneOfType([number, string]),
      nowPrice: oneOfType([number, string]),
      specialBuyPrice: oneOfType([number, string]),
      dollarOff: oneOfType([number, string]),
      percentageOff: oneOfType([number, string]),
      specialBuySavings: oneOfType([number, string]),
      specialBuyDollarOff: oneOfType([number, string]),
      alternatePriceDisplay: bool,
      savingsCenterPromos: string,
      quantityLimit: oneOfType([number, string]),
      unitWasPrice: oneOfType([number, string]),
      storePromotion: shape(),
      unitOfMeasureCoverage: string
    }).isRequired
  }).isRequired,
  /**
   * zipcode used by ecorebates
   */
  zipCode: string,
  disableOuterSpacing: bool,
  isClearance: bool,
  priceBadge: string
};

PriceSimple.defaultProps = {
  basic: false,
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideRebates: false,
  hideSavingsText: false,
  omitDetails: false,
  onSavingsCenterToggle: () => { },
  priceBadge: null,
  zipCode: null,
  disableOuterSpacing: false,
  isClearance: false,
  useNewMapPolicy: false,
};

