import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import { LoadingIcon } from '../private/icons/LoadingIcon';

const LoadingIconButton = React.forwardRef((props, ref) => {
  const {
    buttonSize = 'normal',
    icon,
    color = 'primary',
    disabled,
    iconSize = 'small',
    loading = false,
    'aria-label': ariaLabelProp,
    ...rest
  } = props;

  const ariaLabel = ariaLabelProp || (loading ? 'loading' : undefined);

  return (
    <IconButton
      ref={ref}
      aria-label={ariaLabel}
      buttonSize={buttonSize}
      color={color}
      disabled={disabled || loading}
      icon={loading ? LoadingIcon : icon}
      iconSize={iconSize}
      {...rest}
    />
  );
});

LoadingIconButton.displayName = 'LoadingIconButton';

LoadingIconButton.propTypes = {
  /**
   * Defines the button (container) size
   * @default 'normal'
   */
  buttonSize: PropTypes.oneOf(['tight', 'normal', 'relaxed']),
  /**
   * The icon of the component.
   */
  icon: PropTypes.elementType,
  /**
   * The icon color
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'inverse', 'subtle', 'brand', 'brand-subtle', 'success', 'success-strong', 'danger', 'danger-strong', 'medium', 'warning', 'warning-strong', 'info', 'info-strong']),
  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Defines the icon size
   * @default 'small'
   */
  iconSize: PropTypes.oneOf(['small', 'regular', 'large']),
  /**
   * If true, the loading indicator is shown.
   */
  loading: PropTypes.bool,
  /**
   * @ignore
   */
  'aria-label': PropTypes.string
};

export { LoadingIconButton };
