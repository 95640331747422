import React, { useContext } from 'react';
import { oneOfType, string, number } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useLocation } from '@thd-olt-component-react/router';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { ExperienceContext, ExperienceProvider } from '@thd-nucleus/experience-context';
import { Content } from './Content';

export const ContentContainer = ({ pipTimeout, ...props }) => {

  const ctx = useContext(ExperienceContext);
  const { isCustomerIdentified } = useThdCustomer() || {};

  const { search: querystring, pathname } = useLocation();
  // eslint-disable-next-line react/destructuring-assignment
  let channelName = ctx.channel;
  const urlParams = new URLSearchParams(querystring);

  if (querystring.includes('channel')) {
    if (urlParams.get('channel') !== '') {
      channelName = urlParams.get('channel');
    }
  }

  return (
    <ExperienceProvider value={{ ...ctx, channel: channelName?.toLowerCase() }}>

      <QueryProvider
        cacheKey="content-page"
        defaultVariables={{ name: '', isBrandPricingPolicyCompliant: !!isCustomerIdentified }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Content {...props} />

      </QueryProvider>
    </ExperienceProvider>
  );
};

ContentContainer.propTypes = {
  pipTimeout: oneOfType([string, number])
};

ContentContainer.defaultProps = {
  pipTimeout: '3000'
};